<template>
    <footer>
        <div id="contact" class="footer">
            <div class="container">
                <div class="row pdn-top-30">
                    <div class="col-md-12">
                        <div class="footer-box">
                            <div class="headinga">
                                <h3>Địa chỉ</h3>
                                <span>Hà Nội, Việt Nam</span>
                                <p>
                                    037869xxxx
                                    <br />nguyenlongthanhIT@gmail.com
                                </p>
                            </div>
                            <ul class="location_icon">
                                <li>
                                    <a href="https://www.facebook.com" target="_blank"
                                        ><i class="fa fa-facebook-f"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com" target="_blank"><i class="fa fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com" target="_blank"
                                        ><i class="fa fa-instagram"></i
                                    ></a>
                                </li>
                            </ul>
                            <div class="menu-bottom">
                                <ul class="link">
                                    <li><router-link to="/">Trang chủ</router-link></li>
                                    <li>
                                        <router-link to="/gioi-thieu">Giới thiệu</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/danh-muc-san-pham">Sản phẩm</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/lien-he">Liên hệ</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
